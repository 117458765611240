const view = path => () => import(`../views/pages/lol/${path}.vue`);

export default [
  {
    path: '/b/:id(lol_academia)/leagueGuide',
    name: 'AcademyLanding',
    component: view('AcademyLanding'),
    meta: { id: 1 },
  },
  {
    path: '/b/:id(lol_academia)/leagueResult',
    name: 'LolAcademiaLeagueResult',
    component: view('LolLeaderboard'),
    meta: { id: 2 },
  },
];
