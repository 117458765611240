// params.id에 따른 api path

const lolInfo = { id: 'lol', gameId: 'LOL', title: '리그 오브 레전드' };
const wrInfo = { id: 'wr', gameId: 'WR', title: '와일드 리프트' };
const valInfo = { id: 'val', gameId: 'VALORANT', title: '발로란트' };
const tftInfo = { id: 'tft', gameId: 'TFT', title: '전략적 팀 전투' };

export default {
  lol_donation: { ...lolInfo, league: 'RIOT_LOL_DOCUP_1', legacy: true },
  wr_wariwari: { ...wrInfo, league: 'RIOT_WARI_WARI_1', legacy: true },
  val_shotval: { ...valInfo, league: 'RIOT_SHOT_VAL_1', legacy: true },
  lol_academia: { ...lolInfo, league: 'RIOT_BATTLE_ACADEMIA_1', legacy: true },
  tft_weeklycup: { ...tftInfo, league: 'RIOT_TFT_WEEKLY_1', legacy: true },
  lol: { ...lolInfo, league: 'promotion/LOL' },
  wr: { ...wrInfo, league: 'promotion/WR' },
  val: { ...valInfo, league: 'promotion/VALORANT' },
  tft: { ...tftInfo, league: 'promotion/TFT' },
};
