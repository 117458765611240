<template>
  <PSection app-footer v-if="!$route.meta.footer || $route.meta.footer !== 'hide'">
    <p class="logo" />
    <p class="links">
      <a :href="setTermsLink('privacy')" target="_blank" @click="click('personal_info')">개인정보처리방침</a> <a :href="setTermsLink('service')" target="_blank" @click="click('Service_terms')">서비스 이용 약관</a>
    </p>
    <div class="option">
      <p class="asks">
        <a class="btn-help" @click="askArena">대회 문의하기</a> <a class="btn-help" @click="askCreateArena" href="mailto:jeh@bigpi.co,8911cos@bigpi.co?subject=[대회 개최 및 제휴 문의]">대회 개최 및 제휴 문의</a>
      </p>
      <p class="infos">
        <span @click="onClickBigPi">빅픽처인터렉티브(주)</span>
        <span>대표이사<em>송광준</em></span>
        <span>주소<em>서울시 금천구 두산로 9길 31 빅픽처인터렉티브</em></span>
        <span>사업자등록번호<em>286-87-00056</em></span>
      </p>
      <p class="copyright">
        Copyright @ {{ copyRightYear }} BigPicture Interactive Inc.<br /> All rights reserved.
      </p>
    </div>
  </PSection>
</template>

<script>
// import { PORTAL_URL } from '@/env';
import { historyMove } from '@shared/utils/routerUtils';
import gtag from 'shared/mixins/gtag';

export default {
  name: 'AppFooter',
  mixins: [gtag],
  computed: {
    gameId() {
      return this.$route.params?.id;
    },
    upRouteGameId() {
      return this.gameId?.toUpperCase();
    },
    copyRightYear() {
      switch (this.gameId) {
        case 'tft':
          return 2022;
        default:
          return 2021;
      }
    },
  },
  methods: {
    click(v) {
      this.trackEvent(`Footer_${v}`, 'click', `${this.upRouteGameId}`);
    },
    onClickBigPi() {
      historyMove('https://www.bigpi.co/');
    },
    askArena() {
      this.trackEvent(`Footer_inquiry`, 'click', `${this.upRouteGameId}`);
      window.zE.activate({ hideOnClose: true });
    },
    askCreateArena() {
      this.trackEvent(`Footer_Affiliate`, 'click', `${this.upRouteGameId}`);
      // if (!window.zE) throw Error('Zendesk is not registered, please insert zendesk script');
      // window.zE.activate({ hideOnClose: true });
    },
    goAcademia() {
      this.trackEvent(`Footer_history`, 'click', `${this.upRouteGameId}`);
      window.open('/b/lol_academia');
    },
    setTermsLink(path) {
      return `${process.env.VUE_APP_MEMBERSHIP_URL}/terms/${path}`;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[app-footer] { .-box; .p(60, 27, 54); .bgc(#1f2226); .c(#a4a3ae); .tc; .rel; z-index: 1;
  .intro-up(1, {
    .logo, .btn-lol-legacy {.o(1); .t-y(0);}
  });
  .intro-up(2, {
    .links {.o(1); .t-y(0);}
  });
  .intro-up(2, {
    .option {.o(1); .t-y(0);}
  });
  .logo { .wh(138, 140); .mb(32); .o(0); .t-y(50); transition: .5s; .mh-c;
    &:before { .wh(138, 140); .cnt; .contain('@{logo}/footer_logo.png'); }
  }
  .btn-lol-legacy { .wh(234, 48); .pl(76); .-box; .bgc(#454545); .c(#cacaca); .fs(16, 50); .br(8); .tl; .font-g; .mb(20); .o(0); .t-y(50);
    .bg('/img/asset/footer-academia.svg'); .no-repeat; .bg-s(54, 36); .bg-xy(16, 6);
  }
  .links { .ib-list; .mb(32); .o(0); .t-y(50); transition: .5s;
    > a { .fs(14); .p(6, 10);
    }
  }
  .option {.o(0); .t-y(50); transition: .5s;}
  .asks { .mb(30);
    > a {
      &.btn-help { .ib; .wh(140, 40); .br(12); .mh-c; .-a(#a4a3ae); .br(12); .fs(14, 40px);
        & + .btn-help { .ml(20); }
      }

    }
  }
;
  .infos { .ib-list;
    > span { word-break: keep-all;.fs(12, 20px); .bold; .p(0, 12); .rel;
      &:nth-of-type(1) { .pointer }
      &:before {.cnt; .wh(1, 16); .bgc(#a4a3ae); .abs; .lt(0, 50%); .t-yc;}
      &:not(:nth-of-type(2)):before {.hidden;}
      em {.regular; .ml(10);}
    }
  }
  .copyright { .fs(12, 18px); .mt(24);}
  @media (@tl-up) {
    .p(86, 0, 100);
    .btn-lol-legacy { .abs; .rt(50, 60); .mb(0); }
    .links {
      > a {.fs(14); .p(10, 16); .vam;
        &:nth-of-type(1) {.pl(0);}
        &.btn-help {.ib; .mt(0); .ml(16); .wh(160, 48px); .lh(48px);}
      }
    }
    .infos { .mt(34);
      > span { .fs(14, 20px); .p(0, 29);
        &:before {.visible !important;}
        &:nth-of-type(1):before {.hidden !important;}
      }
    }
    .copyright {.fs(16, 26px); .mt(16);}
  }
  @media (@ds-up) {
    .p(115, 0, 119);
    .btn-lol-legacy { .r(calc(50% - 630px)); }
    .logo { .mb(24) }
  ;
    .links { .mb(20) }
  ;
  }
}
</style>
