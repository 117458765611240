<template>
  <div blocker>
    <em class="c1" /><em class="c2" /><em class="c3" /><em class="c4" /><em class="c5" />
  </div>
</template>
<script>

export default {
  name: 'Blocker',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[blocker] { .ib-list; .pt(160); .abs; .lt(50%, 50%); .t-xyc; .z(2);
  &:before {.cnt; .wh(130, 133); .contain('/img/riot-white.png'); .abs; .lt(50%, 0); .t-xc;}
  em {.wh(10); .m(0, 8); .bgc(#d13639); .br(30); animation-name: c1; animation-duration: 2s; animation-iteration-count: infinite;
    &:nth-of-type(2) {.wh(12); .mt(-1); animation-delay: .2s;}
    &:nth-of-type(3) {.wh(14); .mt(-2); animation-delay: .4s;}
    &:nth-of-type(4) {.wh(16); .mt(-3); animation-delay: .6s;}
    &:nth-of-type(5) {.wh(14); .mt(-2); animation-delay: .8s;}
  }
}
</style>
