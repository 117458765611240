const view = path => () => import(`../views/pages/tftWeeklyCup/${path}.vue`);

export default [
  {
    path: '/b/:id(tft_weeklycup)',
    name: 'tftHome',
    component: view('TftHome'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(tft_weeklycup)/leagueGuide',
    name: 'tftLeagueGuide',
    component: view('TftIntro'),
    meta: { id: 1 },
  },
  {
    path: '/b/:id(tft_weeklycup)/leagueResult',
    name: 'tftLeagueResult',
    component: view('TftResult'),
    meta: { id: 2 },
  },
];
