<template>
  <header riot-header class="b-global-header app-header">
    <nav class="b-gnb">
      <div class="b-container">
        <div class="b-gnb-content">
          <div class="b-gnb-service">
            <a href="https://dak.gg" class="b-logo">
              <img :src="SvgLogoDakGG" alt="Logo"/>
            </a>
            <div class="b-gnb-service-gradation">
              <div class="b-gnb-service-content">
                <div class="b-service">
                  <a href="https://dak.gg" class="b-service-item">전적검색</a>
                  <div class="b-service-divider"></div>
                  <a :href="VUE_APP_HOME_URL" class="b-service-item active">레벨업지지</a>
                  <div class="b-service-divider"></div>
                  <a href="https://wcg.lvup.gg" class="b-service-item hot">WCG</a>
                  <div class="b-service-divider"></div>
                  <a href="https://www.youtube.com/c/GCL%EC%A7%80%EC%94%A8%EC%97%98" target="_blank" class="b-service-item">GCL <img :src="SvgIcoExternal" class="b-external"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="b-container">
      <div class="b-header">
        <div class="b-header-left">
          <div class="b-dropdown-service">
            <button class="b-dropdown-service-button" @click="toggleGameList">
              <CdnImg v-if="selectedInfo" :src="selectedInfo.image" class="b-dropdown-service-icon"/>
              <img :src="SvgIcoArrowDown" alt="Arrow Down" class="b-dropdown-service-arrow"/>
            </button>
            <div class="b-dropdown-service-list" v-if="gameListOn">
              <a v-for="selection in selectionList" class="b-dropdown-service-list-item active"
                 :key="`selection-sm-${selection.id}`"
                 :class="{ selection, 'selected': gameId === selection.id }"
                 @click="pageMove(`/b/${selection.id}`, 'riot')">
                <CdnImg :src="selection.icon" :alt="selection.title" class="b-dropdown-service-list-item-icon"/>
                <span class="b-dropdown-service-list-item-text">{{ selection.title }}</span>
              </a>
            </div>
          </div>
          <h1 class="b-service-title">
            <a class="b-service-title-link" @click="logoClick">라이엇 아마추어 리그</a>
          </h1>
        </div>
        <client-only>
          <div class="b-header-right">
            <div class="b-login-info" v-if="isLogin">
              <BadgeButton class="alarm" :count="unReadCount" @click="openNotification">
                <img src="/img/icon/bell.svg" alt="">
              </BadgeButton>
              <Avatar :info="myInfo" @click="myMenuOn = true" dimension="80x80" userProfile class="b-login-info-image" />
              <div class="b-login-info-list" v-if="myMenuOn">
                <a @click="pageMove(`/channel/ri/${gameId}/${slugId}`, 'lvup')" class="b-login-info-list-item">내 채널</a>
                <div class="b-login-info-list-divider"></div>
                <a @click="pageMove(`/user/ri/${gameId}/me`, 'lvup')" class="b-login-info-list-item">내 정보</a>
                <div class="b-login-info-list-divider"></div>
                <a @click="logout" class="b-login-info-list-item">로그아웃</a>
              </div>
            </div>
            <a @click="onClickLogin" class="b-header-login" v-else>로그인</a>
          </div>
        </client-only>
      </div>
      <div class="b-header-mobile">
        <div class="b-header-left">
          <div class="b-dropdown-service">
            <button class="b-dropdown-service-button" @click="toggleGameList">
              <CdnImg v-if="selectedInfo" :src="selectedInfo.image" class="b-dropdown-service-icon"/>
              <img :src="SvgIcoArrowDown" alt="Arrow Down" class="b-dropdown-service-arrow"/>
            </button>
            <div class="b-dropdown-service-list" v-if="gameListOn">
              <a v-for="selection in selectionList" class="b-dropdown-service-list-item active"
                 :key="`selection-sm-${selection.id}`"
                 :class="{ selection, 'selected': gameId === selection.id }"
                 @click="pageMove(`/b/${selection.id}`, 'riot')">
                <CdnImg :src="selection.icon" :alt="selection.title" class="b-dropdown-service-list-item-icon"/>
                <span class="b-dropdown-service-list-item-text">{{ selection.title }}</span>
              </a>
            </div>
          </div>
          <h2 class="b-lnb-mobile-header-title" @click="logoClick">라이엇 아마추어 리그</h2>
        </div>
        <client-only>
          <div class="b-header-right">
            <div class="b-login-info" v-if="isLogin">
              <BadgeButton class="alarm" :count="unReadCount" @click="openNotification">
                <img src="/img/icon/bell.svg" alt="">
              </BadgeButton>
              <Avatar :info="myInfo" @click="myMenuOn = true" dimension="80x80" userProfile class="b-login-info-image" />
              <div class="b-login-info-list" v-if="myMenuOn">
                <a @click="pageMove(`/channel/ri/${gameId}/${slugId}`, 'lvup')" class="b-login-info-list-item">내 채널</a>
                <div class="b-login-info-list-divider"></div>
                <a @click="pageMove(`/user/ri/${gameId}/me`, 'lvup')" class="b-login-info-list-item">내 정보</a>
                <div class="b-login-info-list-divider"></div>
                <a @click="logout" class="b-login-info-list-item">로그아웃</a>
              </div>
            </div>
            <a @click="onClickLogin" class="b-header-login" v-else>
              <img :src="SvgIcoArrowRightToBracket" alt="Login">
            </a>
          </div>
        </client-only>
      </div>
    </div>
    <div class="items">
      <Slider ref="navSlider" :info="routeTabs" :option="menuSlideOptions" class="nav b-container">
        <template v-slot:slide="{info, idx}">
          <div :class="['menu', {'on': $route.path === info.link}]" @click="menuClick(info.link, idx, info.tag)">
            <h4>{{ info.title }}</h4>
          </div>
        </template>
      </Slider>
    </div>
  </header>
</template>

<script>
import SvgIcoGlobe from '@bigpi-dakgg/style/dist/images/ico-globe.svg';
import SvgIcoGlobe2 from '@bigpi-dakgg/style/dist/images/ico-globe2.svg';
import SvgIcoArrowDown from '@bigpi-dakgg/style/dist/images/ico-arrow-down.svg';
import SvgIcoCheck from '@bigpi-dakgg/style/dist/images/ico-check.svg';
import SvgIcoCheckActive from '@bigpi-dakgg/style/dist/images/ico-check-active.svg';
import SvgLogoLvup from '@bigpi-dakgg/style/dist/images/logo-lvup.svg';
import PngImgPlaceholderProfile from '@bigpi-dakgg/style/dist/images/img-placeholder-profile.png';
import SvgIcoBars from '@bigpi-dakgg/style/dist/images/ico-bars.svg';
import SvgIcoClose from '@bigpi-dakgg/style/dist/images/ico-close.svg';
import SvgImgPlaceholder from '@bigpi-dakgg/style/dist/images/img-placeholder.svg';
import SvgIcoArrowRight from '@bigpi-dakgg/style/dist/images/ico-arrow-right.svg';
import SvgIcoExternal from '@bigpi-dakgg/style/dist/images/ico-external.svg';
import SvgLogoDakGG from '@bigpi-dakgg/style/dist/images/logo-dakgg.svg';
import SvgIcoArrowRightToBracket from '@bigpi-dakgg/style/dist/images/ico-arrow-right-to-bracket.svg';
import _ from 'lodash';
import { getter, state } from '@shared/utils/storeUtils';
import { historyMove } from '@shared/utils/routerUtils';
import { NotificationModal } from '@shared/components/LazyLoadings';
import CdnImg from '@shared/components/common/CdnImg.vue';
import Avatar from '@shared/components/common/Avatar.vue';
import riotGamesMap from '@shared/data/constants/riotGamesMap';
import gtag from '@shared/mixins/gtag';
import BadgeButton from '@shared/components/general/BadgeButton.vue';
import Slider from '@shared/components/common/Slider.vue';
import ClientOnly from '@shared/components/general/ClientOnly.vue';

export default {
  name: 'RiotGnb',
  components: { ClientOnly, Slider, Avatar, CdnImg, BadgeButton },
  mixins: [gtag],
  props: {
    gameId: { type: String, default: '' },
  },
  data() {
    return {
      SvgIcoGlobe,
      SvgIcoGlobe2,
      SvgIcoArrowDown,
      SvgIcoCheck,
      SvgIcoCheckActive,
      SvgLogoLvup,
      PngImgPlaceholderProfile,
      SvgIcoBars,
      SvgIcoClose,
      SvgImgPlaceholder,
      SvgIcoArrowRight,
      SvgIcoExternal,
      SvgLogoDakGG,
      SvgIcoArrowRightToBracket,
      myMenuOn: false,
      menuSlideOptions: {
        slidesPerView: 'auto',
        freeMode: true,
        breakpoints: {
          1280: {
            enabled: false,
          },
        },
      },
      gameListOn: false,
      VUE_APP_HOME_URL: process.env.VUE_APP_HOME_URL,
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    myInfo: state('auth', 'myInfo'),
    gamesByKey: state('info', 'gamesByKey'),
    unReadCount: state('notification', 'unReadCount'),
    upRouteGameId() {
      return this.gameId.toUpperCase();
    },
    slugId() {
      return this.myInfo?.nickname || '';
    },
    hasChannel() {
      return this.myInfo?.hasChannel ?? false;
    },
    selectedInfo() {
      const pathInfos = {
        lol: { title: '리그 오브 레전드', image: this.gamesByKey.LOL.images?.colorIcon },
        lol_donation: { title: '리그 오브 레전드', image: this.gamesByKey.LOL.images?.colorIcon },
        wr: { title: '와일드 리프트', image: this.gamesByKey.WR.images?.colorIcon },
        wr_wariwari: { title: '와일드 리프트', image: this.gamesByKey.WR.images?.colorIcon },
        val: { title: '발로란트', image: this.gamesByKey.VALORANT.images?.colorIcon },
        val_shotval: { title: '발로란트', image: this.gamesByKey.VALORANT.images?.colorIcon },
        lol_academia: { title: '리그 오브 레전드', image: this.gamesByKey.LOL.images?.colorIcon },
        tft: { title: '전략적 팀 전투', image: this.gamesByKey.TFT.images?.colorIcon },
        tft_weeklycup: { title: '전략적 팀 전투', image: this.gamesByKey.TFT.images?.colorIcon },
      };
      return pathInfos[this.gameId];
    },
    selectionList() {
      return _.map(_.filter(riotGamesMap, v => !v.legacy), o => ({ ...o, icon: this.gamesByKey[o.gameId].images?.colorIcon }));
    },
    routeTabs() {
      const tabFactory = (link, title, subtitle, tag) => ({
        link: `/b/${this.gameId}${link}`,
        title,
        subtitle,
        tag,
      });
      const tabs = {
        lol: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
        lol_donation: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/leagueGuide', '대회 안내', '', 'guide'),
          tabFactory('/applyGift', '애장품 응모', '', 'gift'),
          tabFactory('/leagueResult', '대회 결과', '', 'leaderboard'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
        wr: [tabFactory('', '대회 신청', 'LEAGUE', 'league'),
          tabFactory('/newsFeed', '공지/이벤트', 'NEWSFEED', 'newsfeed')],
        wr_wariwari: [tabFactory('', '대회 신청', 'LEAGUE', 'league'),
          tabFactory('/leagueGuide', '대회 안내', '', 'guide'),
          tabFactory('/newsFeed', '공지/이벤트', 'NEWSFEED', 'newsfeed')],
        val: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
        val_shotval: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/leagueGuide', '대회 안내', '', 'guide'),
          tabFactory('/leagueResult', '대회 결과', '', 'leaderboard'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
        lol_academia: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/leagueGuide', '대회 안내', '', 'guide'),
          tabFactory('/leagueResult', '대회 결과', '', 'leaderboard'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
        tft: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
        tft_weeklycup: [
          tabFactory('', '대회 신청', '', 'league'),
          tabFactory('/leagueGuide', '대회 안내', '', 'guide'),
          tabFactory('/leagueResult', '대회 결과', '', 'leaderboard'),
          tabFactory('/newsFeed', '공지/이벤트', '', 'newsfeed'),
        ],
      };
      return tabs[this.gameId];
    },
  },
  watch: {
    recentReceivedType() {
      this.openNotification(this.recentReceivedType);
    },
    gameId() {
      this.$services.cookie.setExternalGameId(this.gameId, 'riot');
    },
    $route() {
      this.gameListOn = false;
    },
  },
  mounted() {
    this.$services.cookie.setExternalGameId(this.gameId, 'riot');
    document.addEventListener('mousedown', this.documentDown);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.documentDown);
  },
  methods: {
    documentDown(e) {
      if (this.myMenuOn
        && !document.querySelector('.b-header .b-login-info-list').contains(e.target)
        && !document.querySelector('.b-header-mobile .b-login-info-list').contains(e.target)) {
        this.myMenuOn = false;
      }
      if (this.gameListOn
        && !document.querySelector('.b-header .b-dropdown-service-list').contains(e.target)
        && !document.querySelector('.b-header-mobile .b-dropdown-service-list').contains(e.target)
        && !document.querySelector('.b-dropdown-service-button').contains(e.target)) {
        this.gameListOn = false;
      }
    },
    toggleGameList() {
      this.trackEvent('GNB_down', 'click', `${this.upRouteGameId}`);
      this.gameListOn = !this.gameListOn;
    },
    closeMyMenu() {
      this.myMenuOn = false;
    },
    onClickLogin() {
      if (this.isLogin) return;
      this.$services.auth.oAuthLogin();
    },
    menuClick(link, index, tag) {
      this.trackEvent(`GNB_${tag}`, 'click', `${this.upRouteGameId}`);
      this.pageMove(link, 'riot');
      this.$refs.navSlider.go(index - 1, 300);
    },
    async openNotification() {
      const isExistModal = await this.$checkModal(NotificationModal);
      if (!isExistModal) this.$modal(NotificationModal);
    },
    pageMove(url, type = 'riot', blank = false) {
      if (process.env.VUE_APP_SERVICE !== 'lvup' && type === 'riot') {
        if (this.$route.path === url) this.$scroll.scrollTo(0);
        else this.$router.push(url);

        this.tracker(url, type);
        return;
      }
      this.tracker(url, type);

      let prevUrl = '';
      if (type === 'riot') prevUrl = process.env.VUE_APP_RIOT_URL;
      else if (type === 'lvup') prevUrl = process.env.VUE_APP_HOME_URL;
      else if (type === 'portal') prevUrl = process.env.VUE_APP_PORTAL_URL;

      url = prevUrl + url;
      if (blank) window.open(url, `${blank ? '_blank' : '_self'}`);
      else historyMove(url);
      this.closeMyMenu();
    },
    tracker(url, type) {
      const gameId = url.match(/b\/(\w+)\/*/)?.[1];
      if (`/b/${gameId}` === url) {
        if (this.gameId === gameId) this.trackEvent('GNB_amateur_logo', 'click', `${this.upRouteGameId}`);
        else this.trackEvent(`GNB_down_${gameId}`, 'click', `${this.upRouteGameId}`);
      } else if (url === '' && type === 'lvup') {
        this.trackEvent('GNB_down_portal', 'click', `${this.upRouteGameId}`);
      }
    },
    async logout() {
      await this.$services.auth.logout();
    },
    logoClick() {
      window.location.href = process.env.VUE_APP_RIOT_URL + this.routeTabs[0].link;
    },
  },
};
</script>
<style lang="less">
@import '~@shared/less/proj.less';
.b-lvup [riot-header].b-global-header { .z(11); .sticky; .t(-45); .pretendard; .bgc(#191919);
  .b-logo { .block; }
  .b-gnb { .bgc(#26292D); }
  h1, h2, h3, h4, h5, h6 { .pretendard; }
  .b-dropdown-service-button { .bgc(#26292D); }
  .b-gnb-service-content { .pl(20); .pr(10); }
  .b-header {
    .alarm { .rel; .mr(41);
      &:after { .cnt; .wh(1, 20); .abs; .rt(-21, 6); .bgc(@c-base-gray);}
    }
  }
  .b-header-mobile {
    .b-dropdown-service-button { .h(36); }
    .alarm { .rel; .mt(2); .mr(15);
      img { .h(20); }
    }
    [avatar] { .wh(24); }
    .b-header-login {
      img { .h(14); }
    }
  }

  .items { .wh(100%, 40); .-box; transition: background-color 0.3s; .tl; .bgc(white);
    .nav { .w(100%); .ib-list; .-box;
      > * { .p(0, 12); }
      .swiper-wrapper { .flex; .h(100%); .rel;}
      .swiper-slide { .w; }
      .menu { .rel; .z(1); .ib; .h(40); .m(0, 12); .fs(13, 40px); .c(rgba(0, 0, 0, 0.4)); .regular; .pointer;
        h4 { .c(rgba(0, 0, 0, 0.4)); }
        p { .russo; }
        &.on {
          h4 { .c(black); }
          p { .c(black); }
          &:after { .cnt; .abs; .lb(0, 0); .z(1); .w(100%); .h(2); .bgc(black); }
        }
      }
    }
    &:after { .cnt; .abs; .lb(0, 0); .z(1); .wh(100%, 2); .bgc(rgba(0, 0, 0, 0.1)); }
  }

  .b-login-info-image { .pointer; }
  .b-login-info-list { .block; .z(1010) }

  .b-lnb-pc-sub-menu:hover { .bgc; .rel;
    &.active { .c(#fff); }
    &:before { .cnt; .block; .abs; .bgc(#2d2f37); .f; .lt; .br(8); .z(-1); .ph(8); .ml(-8); }
  }

  .b-lnb-mobile { .block; }
  .b-locale-list { .block; .t(100%); }
  .b-dropdown-service-list { .block; .t(calc(100% + 4px)) }
  .b-lnb-mobile-menu { height: calc(var(--innerHeight) - 177px); }

  .b-service-item { .rel;
    &.hot:after { .cnt; .wh(4,4); .abs; .br(2); .bgc(#ff4655); .rt(-6,0); }
  }

  @media (@tl-up) {
    .b-login-info-list { .l(83%); }
    .items { .abs; .lb(50%,0); .z(1); .ib; .c(white); .tl; .bgc(transparent); .w(0);
      .nav { .h(100%); .p(0); .wh(500, 70); .abs; .lb(-200,0);
        > * { .p(0, 0); }
        .swiper-container { .wh(100%); }
        .menu { display: inline-flex; justify-content: center; align-items: center; flex-direction: column; .h(100%); .m(0, 12); .c(#fff); .tc;
          a { }
          &.on {
            h4 { .c(white); .bold; }
            &:after { .wh(100%, 4); .l; .bgc(white); }
          }
          h4 { .fs(17, 17px); .c(rgba(255, 255, 255, 0.9)); .regular; }
          &:hover:not(&.on) {
            h4 { .c(rgba(255, 255, 255, 1)); }
          }
        }
      }
    }
  }

  @media (@ds-up) {
    .items {
      .nav { .l(-300); }
    }
  }
}

</style>
