const view = path => () => import(`../views/pages/loldocup/${path}.vue`);

export default [
  {
    path: '/b/:id(lol_donation)/applyGift',
    name: 'loldocupApplyGift',
    component: view('LoldocupEvent'),
    meta: { id: 2 },
  },
  {
    path: '/b/:id(lol_donation)/leagueGuide',
    name: 'loldocupLeagueGuide',
    component: view('LoldocupIntro'),
    meta: { id: 1 },
  },
  {
    path: '/b/:id(lol_donation)/leagueResult',
    name: 'loldocupLeagueResult',
    component: view('LoldocupResult'),
    meta: { id: 3 },
  },
];
