import { getSessionStorage, getPersistentStorage } from '@shared/modules/ObjectStorage';
import { NoToast } from '@shared/modules/ToastErrorHandler';
import Logger from '@shared/modules/Logger';
import { appendQuery, parseQuery } from '@shared/utils/urlUtils';
import LoginRequiredModal from '@shared/components/account/LoginRequiredModal.vue';
import axios from 'axios';
import { Membership } from '@bigpi-dakgg/membership-sdk';

/**
 * 회원가입, 로그인, 로그아웃
 */
export default class AuthService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ApiConnection} */
  #auth;
  /** @type {ServiceManager} */
  #services;

  #log;

  #membershipSdk = new Membership('pubg', process.env.VUE_APP_ENV);

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#api = services.commonApi;
    this.#auth = services.authApi;
    this.#services = services;
    this.#log = Logger('AuthService');
  }

  /**
   * @returns {Promise<string>}
   */
  getImpToken() {
    return new Promise((resolve, reject) => {
      if (typeof window === 'undefined') return reject(null);
      window.IMP.init('imp70541721');
      window.IMP.certification({}, rsp => {
        if (rsp.success) {
          resolve(rsp.imp_uid);
        } else {
          reject(rsp);
        }
      });
    });
  }

  /**
   * @param {('IAMPORT'|'PARENTS_IAMPORT'|'DOCUMENT')} certificateType
   * @param {IamportCertificateRequest|ParentCertificateRequest|DocumentCertificateRequest} certificateRequest
   * @returns {Promise<void>}
   */
  async reverification(certificateType, certificateRequest) {
    try {
      const certificateRequestName = {
        IAMPORT: 'iamportCertificateRequest',
        PARENTS_IAMPORT: 'parentsCertificationRequest',
        DOCUMENT: 'documentCertificationRequest',
      }[certificateType];
      const arg = { certificateType };
      arg[certificateRequestName] = certificateRequest;
      await this.#api.post('/users/verification/retry-verification', arg);
      this.#services.toast.toast('account.successRetryVerification');
      await this.fetchMyInfo();
    } catch ({ code }) {
      throw ['account.signupFail', code];
    }
  }

  /**
   * @description 통합멤버십 로그인
   *
   * @param {boolean} modal 모달 안내 여부
   * @param {string} afterLoginPath 로그인 후 리다이렉트 path
   * @param {'dark' | 'light'} theme 테마 색상
   * @return {Promise<void>}
   */
  async oAuthLogin(modal = false, afterLoginPath = window.location.href, theme = document.querySelector('#app > div > .dark') ? 'dark' : 'light') {
    if (modal) {
      const result = await this.#services.modal.modal(LoginRequiredModal, { theme, contents: 'requiredService' });
      if (!result) throw result;
    }

    getSessionStorage('auth').set('afterLogin', afterLoginPath);
    window.location.href = appendQuery(process.env.VUE_APP_MEMBERSHIP_LOGIN_URL, { service: 'riot', lang: 'ko', theme });
  }

  /**
   * @param {string} userId
   * @param {string} accessToken
   * @param {string} refreshToken
   */
  setUserToken(userId, accessToken, refreshToken) {
    const storage = getPersistentStorage('auth');
    storage.set('userId', userId);
    storage.set('accessToken', accessToken);
    storage.set('refreshToken', refreshToken);
    this.#services.store.commit('auth/setUserToken', { userId, accessToken, refreshToken });
  }
  updateToken({ accessToken, refreshToken }) {
    const storage = getPersistentStorage('auth');
    storage.set('accessToken', accessToken);
    storage.set('refreshToken', refreshToken);
    this.#services.store.commit('auth/updateToken', { accessToken, refreshToken });
  }

  clearUserToken() {
    const storage = getPersistentStorage('auth');
    storage.remove('userId');
    storage.remove('accessToken');
    storage.remove('refreshToken');
    this.#services.store.commit('auth/clearUserToken');
  }

  /**
   * 쿠키에 저장된 토큰이 있는지 확인하고 있으면 store 에 저장한다.
   */
  restoreUserToken() {
    const storage = getPersistentStorage('auth');
    const userId = storage.get('userId');
    const accessToken = storage.get('accessToken');
    const refreshToken = storage.get('refreshToken');
    if (userId && accessToken && refreshToken) {
      this.#services.store.commit('auth/setUserToken', { userId, accessToken, refreshToken });
    } else {
      this.clearUserToken();
    }
  }

  /**
   * 로그인 여부
   * @returns {Boolean}
   */
  get isLogin() {
    return this.#services.store.getGetter('auth', 'isLogin');
  }

  /**
   * 로그인된 사용자 정보
   * @returns {MyInfo}
   */
  get myInfo() {
    return this.#services.store.getState('auth', 'myInfo');
  }

  /**
   * @returns {Promise<*>}
   */
  async logout() {
    await this.#auth.post('/logout');
    this.#services.cookie.removeAutoLogin();
    this.#services.store.commit('auth/clearMyInfo');
    this.clearUserToken();
    this.#membershipSdk.logout(window.location.origin);
  }

  /**
   * entry-client mount 이후에 실행함.
   * ti가 있다면 social signup 진행이 되어야하며, fromUrl 이 있다면 다른 사이트 (portal, riot) 에서 signup 요청을 한경우이므로 해당정보를 store 에 저장하고 signup 페이지로 보낸다.
   * access_token 은 이미 authService.init 에서 처리된 값이므로 query 에서 제거하고, 이경우 로그인 되지마자 랜딩된 경우이므로 sessionStorage 에 로그인 이후에 보내야할 경로가 있다면 해당경로로 보낸다.
   * @param {Route} route
   */
  checkAuthQueries(route) {
    const { at, error, to, code, logout } = route.query;
    if (logout) {
      return this.#services.router.replace({ query: { ...route.query, logout: undefined } });
    }
    if (at) {
      return this.#services.router.replace({ query: { ...route.query, at: undefined, ui: undefined, rt: undefined } });
    }
    if (error) {
      this.#services.toast.toast(['httpErrors', error], { type: 'fail' });
      if (error === '401') this.oAuthLogin(true, to).then();
      return this.#services.router.replace({ query: {} });
    }
    if (code) {
      window.location.href = process.env.VUE_APP_MEMBERSHIP_LOGIN_URL;
    }
  }

  /**
   * 로그인된 유저의 정보를 서버로부터 받아오고 받아오는것을 실패할 경우 토큰이 썩은 상태이므로 저장된 토큰을 제거
   * @returns {Promise<void>}
   */
  async fetchMyInfo() {
    try {
      const user = /** @type{MyInfo} */ await this.#api.get('/users/me');
      this.#services.store.commit('auth/setMyInfo', user);
    } catch (e) {
      this.clearUserToken();
      throw NoToast;
    }
  }

  /**
   * Social Login 과 같은경우 Query 를 통해 토큰값이 전달되므로 쿠키와 store 에 해당 값을 저장하여 로그인처리를 하고 로그인된 상태라면 유저정보를 가져옴
   * @param {string} url
   * @returns {Promise<void>}
   */
  async init(url) {
    const { at, ui, rt, logout } = parseQuery(url);
    if (logout) this.clearUserToken();
    else if (at && ui && rt) this.setUserToken(ui, at, rt);
    else this.restoreUserToken();
    if (this.isLogin) await this.fetchMyInfo();
    else this.#services.store.commit('auth/clearMyInfo');
  }

  /**
   * 나인지 여부
   * @param {string} user
   * @returns {boolean}
   */
  isMe(user) {
    return user === 'me' || this.myInfo?.id === user;
  }

  async refreshToken() {
    const { refreshToken } = this.#services.store.state.auth;
    const res = await axios.post(`${process.env.VUE_APP_AUTH_URL}/api/bigpi/refresh`, { refreshToken }, { withCredentials: true });
    this.updateToken(res.data.body);
  }
}
