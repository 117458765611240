const view = path => () => import(`../views/pages/valorant/${path}.vue`);

export default [
  {
    path: '/b/:id(val_shotval)/leagueGuide',
    name: 'ShotValLanding',
    component: view('ShotValLanding'),
    meta: { id: 1 },
  },
  {
    path: '/b/:id(val_shotval)/leagueResult',
    name: 'ShotValLeagueResult',
    component: view('ValorantLeaderboard'),
    meta: { id: 2 },
  },
];
