// eslint-disable-next-line no-unused-vars
import riotGamesMap from '@shared/data/constants/riotGamesMap';

export default class HomeService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  #leagueCode = id => riotGamesMap[id].league;

  async getHeaderBanners(routeId) {
    const url = `/leagues/${this.#leagueCode(routeId)}`;
    return Promise.all([this.#api.get(`${url}/banners/main`), this.#api.get(`${url}/banners/sub`)]);
  }

  getArena(params) {
    return this.#api.get('/arenas', params);
  }

  getMyTeamHistory({ teamId, cursor, size }) {
    return this.#api.get(`/teams/${teamId}/history`, { cursor, size });
  }

  getMyTeams(params) {
    return this.#api.get('/new/teams', params);
  }

  getAcademiaSummary() {
    return this.#api.get('/leagues/RIOT_BATTLE_ACADEMIA_1/summary');
  }

  setLolProviders(params) {
    return this.#api.get('/users/me/providers/lol', params);
  }

  getLolSeasonStatus(params) {
    return this.#api.get('/third-party/lol/season/stats', params);
  }

  getUniversityRankings({ isMy, leagueCode, params }) {
    let url = `/leagues/${leagueCode}/university-rankings`;
    url += isMy ? '/users/me' : '';
    return this.#api.get(url, params);
  }

  getTeamRankings({ isMy, leagueCode, params }) {
    let url = `/leagues/${leagueCode}/team-rankings`;
    url += isMy ? '/users/me' : '';
    return this.#api.get(url, params);
  }

  getLolDonationValue() {
    return {
      "leagueId": "617f4ca830742303d2bda719",
      "leagueCode": "RIOT_LOL_DOCUP_1",
      "totalDonation": 6517000,
      "countUserApply": 6517,
      "createdTime": 1639139520677,
    };
  }
}
