<template>
  <div id="app" class="b-lvup" :class="[touch, loading, {window:isWindow}]">
    <div>
      <RiotHeaderNav :game-id="gameId" />
      <scroll-holder id="container" container-class="scroll-container" body-class="scroll-body" offset-target-class="app-header" :header-offset="45">
        <router-view />
        <AppFooter />
        <TwoButtonGoTop />
      </scroll-holder>
    </div>
  </div>
</template>

<script>
import RiotHeaderNav from 'shared/components/layout/RiotHeaderNav.vue';
import ExecModal from '@/utils/execModal';
import AppFooter from '@/views/common/AppFooter.vue';
import TwoButtonGoTop from '@/views/components/common/button/TwoButtonGoTop.vue';

export default {
  name: 'App',
  components: { AppFooter, RiotHeaderNav, TwoButtonGoTop },
  data() {
    return {
      isWindow: null,
      isLoading: true,
    };
  },
  metaInfo() {
    return {
      title: '',
      meta: [
        { vmid: 'title', content: '' },
        { vmid: 'description', content: '' },
        { vmid: 'image', content: '' },
        { vmid: 'keywords', content: '' },
      ],
    };
  },
  computed: {
    touch() {
      return this.$store.state.browser.touch ? 'touch' : 'no-touch';
    },
    loading() {
      return this.$store.state.loading && 'loading';
    },
    gameId() {
      return this.$route.params.id || '';
    },
  },
  methods: {
    getOs() {
      this.isWindow = navigator.userAgent.toLowerCase().indexOf('win') > -1;
    },
  },
  mounted() {
    this.getOs();
    // eslint-disable-next-line no-new
    new ExecModal(this);
  },
};
</script>

<style lang="less">
@import '~@/less/common.less';
@import '~@/less/proj.less';

#app {
  [riot-header] {
    &::before, &::after { .bgc(transparent); }
    .contents-holder { .max-w(1260); }
  }
}
</style>
