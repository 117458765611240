import 'swiper/swiper-bundle.css';
import modal from '@shared/plugins/modal';
import toast from '@shared/plugins/toast';
import pusher from '@shared/plugins/pusher';
import popover from '@shared/plugins/popover';
import browser from '@shared/modules/Browser';
import { sha256 } from '@shared/utils/hashUtils';
import gtag from '@/plugins/gtag';
import app from '@/app';
import Blocker from '@/views/components/common/modal/Blocker.vue';

export default app.getEntryClient({
  beforeCreate: async (Vue, store, router, services) => {
    Vue.config.productionTip = false;
    Vue.use(modal, 'riot', Blocker);
    Vue.use(toast);
    Vue.use(pusher);
    Vue.use(popover);
    await store.dispatch('timeInit');
    browser.sync(store);

    await services.auth.init(window.location.href);
    const user_id = await sha256(store?.state?.auth?.userId);
    Vue.use(gtag, { router, user_id });
    await services.notification.init();
  },
  makeRouteErrorHandler: (services, router) => async e => {
    const { code } = e;
    if (code === 301 || code === 302) await router.replace(e.to);
    if (code === 401 || code === 403) {
      services.toast.toast(['httpErrors', code], { type: 'fail' });
      if (code === 401) await services.auth.oAuthLogin(true, e.to);
    }
  },
  afterCreate: (app, router, store) => {
    browser.useScrollHistory({ router, store, scrollHandler: app.$scroll });
  },
});
