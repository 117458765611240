import riotGamesMap from '@shared/data/constants/riotGamesMap';

export default class ApplyGiftService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  #leagueCode = gameId => riotGamesMap[gameId];

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  /**
   * @description 응모 아이템 조회
   * @param {string} gameId
   * @returns {Promise<CherishItem[]>}
   */
  getCherisedItems(gameId) {
    const code = this.#leagueCode(gameId).league;
    return this.#api.get(`/leagues/${code}/cherished-items`);
  }

  async isExistTicket(gameId) {
    if(gameId !== 'lol') return { isConsumable: false, errorCode: 'NOT_LOLDOCUP' };
    const code = this.#leagueCode(gameId).league;
    const result = { isConsumable: false, errorCode: '' };
    try{
      result.isConsumable = (await this.#api.get(`/leagues/${code}/draw-ticket`)).isConsumable;
    }catch(e) {
      result.errorCode = e.code;
    }

    return result;
  }

  /**
   * @description 응모권 사용
   * @param {string} gameId
   * @param {string} cherishedItemId
   * @returns {Promise<*>}
   */
  useApplyGiftTicket({ gameId, cherishedItemId }) {
    const code = this.#leagueCode(gameId).league;
    return this.#api.put(`/leagues/${code}/draw-ticket`, { cherishedItemId });
  }
}
