const view = path => () => import(`../views/pages/end/${path}.vue`);

// noinspection JSUnresolvedVariable
export default [
  {
    path: '/b',
    name: 'lolRoot',
    redirect: '/b/lol',
  },
  {
    path: '/b/:id(lol)',
    name: 'lolEnd',
    component: view('LolEnd'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(wr)',
    name: 'wrEnd',
    component: view('WrEnd'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(val)',
    name: 'valEnd',
    component: view('ValEnd'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(tft)',
    name: 'tftEnd',
    component: view('TftEnd'),
    meta: { id: 0 },
  },
];
