import Vue from 'vue';
import Vuex from 'vuex';
import { store as browser } from '@shared/modules/Browser';
import scrollAgency from '@shared/plugins/inertiaScrollAgency';
import auth from './auth.store';
import info from './info.store';
import notification from './notification.store';
import time from './time.store';
// /**
//  *
//  * @param {array} gameInfos
//  * @return {object}
//  */
// const gameInfoMapper = (gameInfos) => {
//   const temp = {};
//   _cloneDeep(gameInfos).forEach((info) => {
//     const { game, runningArenaCount } = info;
//     temp[game.id.toLowerCase()] = { ...game, runningArenaCount };
//   });
//   return temp;
// };

Vue.use(Vuex);

/**
 * @returns {Vuex.Store}
 */
export default () => new Vuex.Store({
  state: {
    theme: 'default',
    loading: false,
    baseInfo: null,
    gameInfo: null,
  },
  mutations: {
    theme(state, val) {
      state.theme = val;
    },
    startLoading(state) {
      state.loading = true;
    },
    endLoading(state) {
      state.loading = false;
    },
    baseInfo(state, value) {
      state.baseInfo = value;
    },
    gameInfo(state, value) {
      state.gameInfo = value;
    },
  },
  actions: {
  },
  getters: {
    inertia(state) {
      return !state.browser.touch;
    },
  },
  modules: {
    auth: auth(),
    info: info(),
    browser: browser(),
    notification: notification(),
    time: time(),
    scrollAgency: scrollAgency.store,
  },
});
