const view = path => () => import(`../views/pages/wildRift/${path}.vue`);

export default [
  {
    path: '/b/:id(wr_wariwari)/leagueGuide',
    name: 'WariwariLanding',
    component: view('WariwariLanding'),
    meta: { id: 1 },
  },
];
