export default class ArenasService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  /**
   * @param {number} cursor
   * @param {number?} size
   * @param {string} gameId
   * @param {string} lvupSpecialId
   * @returns {Promise<ArenaInfoList>}
   */
  getCommunityRunningArenas(size, cursor, gameId, lvupSpecialId) {
    const query = {
      order: 'schedule.start asc',
      cursor: cursor ?? 0,
      size: size ?? 24,
      q: `isRunning eq true and gameId eq ${gameId} and isChannelArena eq true and organizerType eq open and lvupSpecialId ne ${lvupSpecialId}`,
    };
    return this.#api.get('/arenas', query);
  }

  /**
   * @param {number?} cursor
   * @param {number?} size
   * @param {string} gameId
   * @param {string} organizerType
   * @param {string} lvupSpecialId
   * @returns {Promise<ArenaInfoList>}
   */
  getSpecialRunningArenas(size, cursor, gameId, organizerType, lvupSpecialId) {
    const query = {
      order: 'schedule.start asc',
      cursor: cursor ?? 0,
      size: size ?? 24,
      q: `isRunning eq true and gameId eq ${gameId} and isChannelArena eq true and organizerType eq ${organizerType} and lvupSpecialId ne ${lvupSpecialId}`,
    };
    return this.#api.get('/arenas', query);
  }

  getSupporterRunningArenas(size, cursor, gameId, lvupSpecialId) {
    const query = {
      order: 'schedule.start asc',
      cursor,
      size,
      q: `isRunning eq true and gameId eq ${gameId} and isChannelArena eq true and lvupSpecialId eq ${lvupSpecialId}`,
    };
    return this.#api.get('/arenas', query);
  }
}
