import riotGamesMap from '@shared/data/constants/riotGamesMap';

export default class ChannelService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  #leagueCode = id => riotGamesMap[id].league;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  /**
   * 채널피드 메타데이터 조회
   * @param {string} routeId
   * @returns {Promise<FeedMeta>}
   */
  getFeedMeta(routeId) {
    return this.#api.get(`/leagues/${this.#leagueCode(routeId)}/feeds/metadata`);
  }

  /**
   * 채널 피드 조회
   * @param {string} category
   * @param {string} routeId
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<ChannelFeedList>}
   */
  getChannelFeed({ category, routeId, cursor = 0, size = 1 }) {
    return this.#api.get(`/leagues/${this.#leagueCode(routeId)}/feeds?category=${category}`, { cursor, size });
  }

  /**
   * 개인 채널 피드 작성
   * @param {string} routeId
   * @param {RequestFeedInfo} body
   * @returns {Promise<*>}
   */
  async makeFeed({ routeId, body }) {
    return this.#api.post(`/leagues/${this.#leagueCode(routeId)}/feeds`, body);
  }

  /**
   * 개인 채널 피드 수정
   * @param {string} routeId
   * @param {string} feedId
   * @param {RequestFeedInfo} body
   * @returns {Promise<*>}
   */
  async editFeed({ routeId, feedId, body }) {
    return this.#api.put(`/leagues/${this.#leagueCode(routeId)}/feeds/${feedId}`, body);
  }

  /**
   * 개인 채널 피드 삭제
   * @param {string} routeId
   * @param {string} feedId
   * @returns {Promise<*>}
   */
  async deleteFeed({ routeId, feedId }) {
    await this.#api.delete(`/leagues/${this.#leagueCode(routeId)}/feeds/${feedId}`);
  }

  /**
   * 개인 채널 피드 댓글 목록
   * @param {string} routeId
   * @param {string} feedId
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<*>}
   */
  async getFeedThreads({ routeId, feedId, cursor = 0, size = 10 }) {
    return this.#api.get(`/leagues/${this.#leagueCode(routeId)}/feeds/${feedId}/threads`, { cursor, size });
  }

  /**
   * 개인 채널 피드 댓글 작성
   * @param {string} gameId
   * @param {string} feedId
   * @param {string} text
   * @returns {Promise<*>}
   */
  addFeedThread({ feedId, text }) {
    return this.#api.post('/users/me/lvupSpecialFeedThreads', { feedId, text });
  }

  /**
   * 개인 채널 피드 댓글 수정
   * @param {string} gameId
   * @param {string} threadId
   * @param {string} text
   * @returns {Promise<{}>}
   */
  editFeedThread({ threadId, text }) {
    return this.#api.put(`/users/me/riotFeedThreads/${threadId}`, { text });
  }

  /**
   * 개인 채널 피드 댓글 삭제
   * @param {string} threadId
   * @returns {Promise<{}>}
   */
  deleteFeedThread(threadId) {
    return this.#api.delete(`/users/me/riotFeedThreads/${threadId}`);
  }

  getCommercialList(routeId) {
    return this.#api.get(`/leagues/${this.#leagueCode(routeId)}/banners/sponsor`);
  }
}
