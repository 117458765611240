import qs from 'qs';
import ApiConnection from '@shared/modules/ApiConnection';
import StoreProxy from '@shared/modules/StoreProxy';
import RouterProxy from '@shared/modules/RouterProxy';
import ToastProxy from '@shared/modules/ToastProxy';
import ModalProxy from '@shared/modules/ModalProxy';
import UserService from './UserService';
import AuthService from './AuthService';
import CookieService from './CookieService';
import NotificationService from './NotificationService';
import AwsService from './AwsService';
import InfoService from './InfoService';
import HomeService from './HomeService';
import LeaderBoard from './LeaderBoardService';
import VoteService from './VoteService';
import ChannelService from './ChannelService';
import ApplyGiftService from './ApplyGiftService';
import ArenasService from './ArenasService';

/**
 * @implements {ProxyManager}
 */
class ServiceManager {
  static cnt = 0;

  id;
  /** @type {StoreProxy} */ store;
  /** @type {ToastProxy} */ toast;
  /** @type {ModalProxy} */ modal;
  /** @type {CookieService} */ cookie;

  constructor({ store = null, cookies = null } = {}) {
    this.id = ServiceManager.cnt++;
    this.store = new StoreProxy(store);
    this.router = new RouterProxy();
    this.toast = new ToastProxy();
    this.modal = new ModalProxy();
    this.cookie = new CookieService(cookies);
  }

  applyLvupPage(config) {
    const route = this.router.nextRoute;
    if (!route) return config;
    const c = config || {};
    if (!c.headers) c.headers = {};
    const queryString = qs.stringify(route?.query);
    c.headers['X-LVUP-PAGE'] = encodeURI(route.path) + (queryString ? (`?${queryString}`) : '');
    return c;
  }

  /* API */
  #commonApi;
  get commonApi() {
    if (!this.#commonApi) this.#commonApi = new ApiConnection(TARGET_NODE ? process.env.VUE_APP_INTERNAL_API_URL : process.env.VUE_APP_API_URL, this, this.applyLvupPage.bind(this));
    return this.#commonApi;
  }

  #authApi;
  get authApi() {
    if (!this.#authApi) this.#authApi = new ApiConnection(process.env.VUE_APP_AUTH_URL, this, this.applyLvupPage.bind(this));
    return this.#authApi;
  }

  #constructors = {
    aws: AwsService,
    auth: AuthService,
    user: UserService,
    notification: NotificationService,
    info: InfoService,
    vote: VoteService,
    home: HomeService,
    channel: ChannelService,
    leaderboard: LeaderBoard,
    applyGift: ApplyGiftService,
    arenas: ArenasService,
  };

  #instances = {};

  getService(name) {
    if (!this.#instances[name]) this.#instances[name] = new this.#constructors[name](this);
    return this.#instances[name];
  }

  /** @type {AwsService} */
  get aws() { return this.getService('aws'); }
  /** @type {AuthService} */
  get auth() { return this.getService('auth'); }
  /** @type {UserService} */
  get user() { return this.getService('user'); }
  /** @type {NotificationService} */
  get notification() { return this.getService('notification'); }
  /** @type {ArenasService} */
  get vote() { return this.getService('vote'); }
  /** @type {ChannelService} */
  get channel() { return this.getService('channel'); }
  /** @type {InfoService} */
  get info() { return this.getService('info'); }
  /** @type {HomeService} */
  get home() { return this.getService('home'); }
  /** @type {ApplyGiftService} */
  get applyGift() { return this.getService('applyGift'); }

  /** @type {HomeService} */
  get leaderboard() { return this.getService('leaderboard'); }

  /** @type {ArenasService} */
  get arenas() { return this.getService('arenas'); }

  mounted(app, route) {
    this.toast.setApp(app);
    this.modal.setApp(app);
    this.notification.setApp(app);
    this.auth.checkAuthQueries(route);
  }

  init() {
    return Promise.all([
      this.info.init(),
    ]);
  }

  setRouter(router) {
    this.router.setRouter(router);
  }
}

export const servicePlugin = {
  install(Vue, { services }) {
    Vue.prototype.$services = services;
  },
};

const createServiceManager = ({ store, cookies }) => new ServiceManager({ store, cookies });

export default createServiceManager;
