/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import gameHome from '@/router/gameHome';
import academia from '@/router/lol';
import loldocup from '@/router/loldocup';
import tftWeeklyCup from '@/router/tftWeeklyCup';
import valorant from '@/router/valorant';
import wildRift from '@/router/wildRift';
import endGame from '@/router/endGame';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const page = path => () => import(`../views/pages${path}.vue`);

Vue.use(VueRouter);
Vue.use(Meta);

const notFound = TARGET_NODE
  ? []
  : [
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/404',
      name: 'empty',
      component: page('/Error'),
    },
  ];

const routes = [
  {
    path: '/',
    redirect: '/b/wr',
  },
  {
    path: '/leagueGuide',
    redirect: '/b/tft/leagueGuide',
  },
  {
    path: '/b/:id(lol|lol_donation|wr_wariwari|val_shotval|tft_weeklycup|tft|wr|val|lol_academia)/newsFeed',
    name: 'newsFeed',
    component: page('/NewsFeed'),
    meta: { id: 2 },
  },
  // {
  //   path: `/b/:id(lol|tft)/newsFeed`,
  //   name: 'newsFeed',
  //   component: page('/NewsFeed'),
  //   meta: { id: 4 },
  // },
  ...gameHome,
  ...endGame,
  ...academia,
  ...wildRift,
  ...valorant,
  ...loldocup,
  ...tftWeeklyCup,
  ...notFound,
];

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => {
    if (isNavigationFailure(error, NavigationFailureType.cancelled)) {
      console.error(error);
    }
  });
};

const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error => {
    if (isNavigationFailure(error, NavigationFailureType.cancelled)) {
      console.error(error);
    }
  });
};

export default () => new VueRouter({
    mode: 'history',
    scrollBehavior: () => null,
    base: process.env.BASE_URL,
    routes,
  });
