const view = path => () => import(`../views/pages/gameHome/${path}.vue`);

// noinspection JSUnresolvedVariable
export default [
  {
    path: '/b/:id(lol_donation)',
    name: 'loldocupHome',
    component: view('LoldocupHome'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(lol_academia)',
    name: 'lolAcademiaHome',
    component: view('LolHome'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(wr_wariwari)',
    name: 'wrHome',
    component: view('WrHome'),
    meta: { id: 0 },
  },
  {
    path: '/b/:id(val_shotval)',
    name: 'valorantHome',
    component: view('ValorantHome'),
    meta: { id: 0 },
  },
];
